<template>
  <div class="magazine-index">
    <HeaderClean :header="$t('magazine.header')" :subheader="$t('magazine.subheader')"/>
    <v-container>
      <div class="magazine-index-tags" v-if="$route.query.tags">
        <v-row no-gutters align=center>
          <h6 class="font-16">
            {{$t('common.tags')}}:
          </h6>
          <v-chip-group column>
            <v-chip close small link label outlined color="accent darken-3" @click:close="resetTagsSearch">{{$route.query.tags}}</v-chip>
            <!-- <v-chip v-else v-for="tag in tags" :key="tag" small label outlined color="accent darken-3" :href="`/magazine?tags=${tag}`" @click.prevent="searchTag(tag)">{{tag}}</v-chip> -->
          </v-chip-group>
        </v-row>
        <v-divider class="margin-top-20"/>
      </div>

      <div v-for="article in articles" :key="article.id" class="magazine-index-article-box">
        <article-box :article="article"/>
        <!-- <v-divider class="margin-top-20"/> -->
      </div>
    </v-container>

    <v-row justify="center" v-if="showLoadMore">
      <v-btn  color="secondary"
              :loading="loading"
              :block="$vuetify.breakpoint.xsOnly"
              @click="loadArticles"
              v-intersect.quiet="loadArticles">
        {{$t('common.load_more')}}
      </v-btn>
    </v-row>
  </div>
</template>

<script>
import HeaderClean from '@/app/common/components/header-clean'
import MagazineService from '@/app/modules/magazine/magazine-service'
import ArticleBox from '@/app/modules/magazine/components/article-box'

export default {

  name: 'articles',

  metaInfo () {
    return {
      title: 'Rbns Magazine'
    }
  },

  components: {
    HeaderClean,
    ArticleBox
  },

  data () {
    return {
      articles: [],
      page: 1,
      showLoadMore: false
    }
  },

  methods: {
    async loadArticles (page = 1, limit = 10) {
      const res = await MagazineService.getArticles(page, limit)
      this.articles = [...this.articles, ...res]
      this.showLoadMore = !(res.length < limit)
      this.page++
    },

    async loadArticlesByTag (page = 1, limit = 10) {
      const res = await MagazineService.searchArticles(this.$route.query.tags, page, limit)
      this.articles = [...this.articles, ...res]
      this.showLoadMore = !(res.length < limit)
      this.page++
    },

    searchTag (tag) {
      this.$router.push({ name: 'magazine', query: { tags: tag } })
      this.articles = []
      this.loadArticlesByTag()
    },

    resetTagsSearch () {
      this.$router.push({ name: 'magazine' })
      this.articles = []
      this.page = 1
      this.loadArticles()
    }
  },

  created () {
    if (this.$route.query.tags) {
      this.loadArticlesByTag()
    } else {
      this.loadArticles()
    }
  }
}
</script>

<style lang="scss">
  .magazine-index {

    .container {
      max-width: 900px;
      margin-top: 35px;
    }

    &-tags {
      margin-bottom: 35px;

      .v-chip--clickable {
        cursor: default;
      }
    }

    &-article-box {
      margin-bottom: 35px;

      &:last-child {

        .v-divider {
          display: none;
        }
      }
    }
  }
</style>
