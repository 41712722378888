<template>
  <v-container v-if="loaded" fluid class="static-page article-page no-padding">
    <article>
      <header-parallax :image="article.assets[0].provider_id" :options="{crop: 'fill'}"/>

      <v-container>
        <v-row>
          <v-col>
            <section class="padding-tb-20">
              <v-row no-gutters>
                <v-col>
                  <h1 class="font-45">{{article.title}}</h1>
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <span class="article-page-author">
                    <span itemprop="author">{{article.author}}</span> &nbsp;|&nbsp; <time datetime="">{{article.created_at | moment('MM/YYYY')}}</time>
                  </span>
                </v-col>
              </v-row>
            </section>

            <section v-html="article.content" class="font-18"/>

            <v-divider class="margin-tb-20"/>
            <v-row no-gutters align=center>
              <h6 class="font-16">
                {{$t('common.tags')}}:
              </h6>
              <v-col cols=10 sm="">
                <v-chip-group column>
                  <v-chip v-for="tag in article.keywords" :key="tag" small label outlined color="accent darken-3" :to="{name: 'magazine', query: {tags: tag}}">{{tag}}</v-chip>
                </v-chip-group>
              </v-col>

              <aside :class="{'margin-top-15': $vuetify.breakpoint.xsOnly}">
                <v-row no-gutters align=center>
                  <share-network network="facebook"
                                  :url="articleUrl('facebook')"
                                  :title="article.title"
                                  @open="share('facebook')">
                    <v-icon class="icon-xl social-icon facebook-icon">mdi-facebook</v-icon>
                  </share-network>
                  &nbsp;
                  <share-network network="whatsapp"
                                  :url="articleUrl('whatsapp')"
                                  :title="article.title"
                                  @open="share('whatsapp')">
                    <v-icon class="icon-xl social-icon whatsapp-icon">mdi-whatsapp</v-icon>
                  </share-network>
                  &nbsp;
                  <copy-text :text="articleUrl('copy')" :tooltip="$t('estates.copy_link')" :sizes="{xs: 'xl'}"/>
                </v-row>
              </aside>
            </v-row>
          </v-col>

          <v-col cols=12 lg=4 tag="aside">
            <v-row class="fill-height">
              <v-col cols=12 lg=1 class="padding-top-35">
                <v-divider :vertical="$vuetify.breakpoint.lgAndUp"/>
              </v-col>
              <v-col>
                <h3 class="font-24 padding-tb-20">{{$t('magazine.sidebar_title')}}</h3>
                <v-row>
                  <v-col cols=12 lg=12 sm=4 class="padding-bottom-20" v-for="estate in estates" :key="estate.id" >
                    <estate-box :estate="estate"/>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </article>

    <v-container class="padding-bottom-50">
      <v-divider class="padding-bottom-35"/>
      <vue-markdown>{{$t('about.text_images_rows')[1].text}}</vue-markdown>

      <h6 class="font-16">{{ $t("magazine.follow_us") }}</h6>
      <v-row class="social-links" no-gutters align=end>
        <social-button for="facebook" share size="small" />
        <social-button for="instagram" share size="small" />
      </v-row>

      <h6 class="font-16 padding-tb-15">{{ $t("magazine.more_help") }}</h6>
      <quick-contact inline>
        <template v-slot:hidden-fields>
          <input type="hidden" name="source" value="magazine">
        </template>
        <template v-slot:custom-fields>
          <v-textarea name="message" dense outlined auto-grow rows="3" :label="$t('contact.message_label')"/>
        </template>
      </quick-contact>
    </v-container>
  </v-container>
</template>

<script>
import MagazineService from '@/app/modules/magazine/magazine-service'
import EstatesService from '@/app/modules/estates/estates-service'
import HeaderParallax from '@/app/common/components/header-parallax'
import CopyText from '@/common/components/copy-text'
import QuickContact from '@/common/components/quick-contact'
import SocialButton from '@/common/components/social-button'
import EstateBox from '@/app/modules/estates/components/estate-box'

export default {

  name: 'Article',

  metaInfo () {
    return {
      title: this.metaTitle,
      meta: [
        {
          property: 'og:title',
          content: this.metaTitle,
          vmid: 'og:title'
        },
        {
          property: 'og:description',
          content: this.metaOgContent,
          vmid: 'og:description'
        },
        {
          property: 'description',
          content: this.metaContent,
          vmid: 'description'
        },
        {
          property: 'author',
          content: this.metaAuthor,
          vmid: 'description'
        },
        {
          property: 'keywords',
          content: this.metaKeywords,
          vmid: 'og:image'
        },
        {
          property: 'og:image',
          content: this.metaImage,
          vmid: 'og:image'
        }
      ]
    }
  },

  components: {
    HeaderParallax,
    CopyText,
    QuickContact,
    SocialButton,
    EstateBox
  },

  data () {
    return {
      article: {},
      loaded: false,
      estates: []
    }
  },

  computed: {
    metaTitle () {
      return this.article.title
    },

    metaImage () {
      return this.article.image_url
    },

    metaContent () {
      return `${this.article.title} by ${this.article.author} on Rbns magazine` // TODO: translations
    },

    metaAuthor () {
      return this.article.author
    },

    metaKeywords () {
      return this.article.keywords ? this.article.keywords.toString().replace(/,/g, ' ') : ''
    }
  },

  methods: {
    share (network) {
      this.$mixpanel.track('magazine_share', { slug: this.article.slug, shared_on: network, id: this.article.id })
      this.$mixpanel.trackShare(network)
    },

    articleUrl (source) {
      return `${window.location.protocol}://${window.location.host}/magazine/${this.article.slug}?utm_source=${source}`
    },

    loadEstates () {
      return EstatesService.getEstatesByPromotePage({ limit: '10' })
    },

    getRandomEstates (estates) {
      return estates.sort(() => 0.5 - Math.random()).slice(0, 3)
    }
  },

  async created () {
    this.article = await MagazineService.getArticle(this.$attrs.slug)
    const estates = await this.loadEstates()
    this.estates = this.getRandomEstates(estates)
    this.loaded = true
  }
}
</script>

<style lang="scss">
  .article-page {

    h1 {
      color: var(--v-accent-darken2);
    }

    &-author {
      display: flex;
      font-size: $font-sm;
      color: var(--v-secondary-lighten5);
    }

    .copy-text {

      @include xs-only {
        margin-top: 2px;
      }
    }
  }
</style>
